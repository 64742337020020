<template>
    <activaitor
        @ok="$router.push('/admin/activities/00000000-0000-0000-0000-000000000000')"
        placeholder="ابحث عن نشاط محدد"
        @search="search"
        btnText="نشاط جديد"
    ></activaitor>
</template>
<script>
import activaitor from "@Ekcore/components/EK-dialog/activaitor.vue";
export default {
    components: {
        activaitor
    },
    methods:{
        search(query) {
            this.$store.commit('Set_Search_Dto', {
                keys: ['title'],
                query
            })
        }
    }
};
</script>